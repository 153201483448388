import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GeistProvider, CssBaseline } from "@geist-ui/core";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GeistProvider themeType='dark'>
      <CssBaseline />
      <App />
    </GeistProvider>
  </React.StrictMode>
);

reportWebVitals();
