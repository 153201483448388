import { Page, Text, Image, Display, Button, Grid, Spinner, Modal } from '@geist-ui/core'
import logo from '../src/Assets/Bittenoli.svg'
const App = () => {
  return (
      <div style={{flexDirection:'column', display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
        <Display margin={0}>
          <Image height="250px" draggable="false" src={logo} className="fade-in-image"/>
        </Display>
        <Spinner/>
        
      </div>
  )
}

export default App